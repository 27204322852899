@media all and (max-width: 1199px) {
   .menu_option h1 {
      font-size: 24px;
   }
   .book_now a {
      font-size: 27px;
      position: relative;
   }
   .banner_all_content h1 {
      font-size: 42px;
   }
   .heading h2 {
      font-size: 36px;
      padding: 26px 0;
      text-align: center;
   }
   .heading p {
      font-size: 14px;
   }
   .w-img2 {
      width: 18%;
   }
   .button-search-score {
      padding-left: 5.7rem !important;
      padding-right: 5.7rem !important;
   }
}

@media all and (max-width: 991px) {
   #menuToggle input {
      top: 10px;
      right: 1.3vw;
   }
   .events-icon-row1 {
      margin-top: 37%;
   }
   .logo img {
      height: 49px;
      width: auto;
   }
   .menu_option h1 {
      margin-right: 18px;
      font-size: 20px;
   }
   #menuToggle span {
      width: 30px;
      height: 2px;
      margin-bottom: 6px;
   }
   .banner_area_desing {
      padding-top: 119px;
      padding-bottom: 141px;
      text-align: center;
      position: relative;
      /* margin-top: 60px; */
   }
   .shap1 {
      right: 0;
      top: -0.7%;
   }
   .heading-aboutUs {
      /*margin-top: -17px;*/
   }
   .heading-aboutUs h2 {
      padding-top: 16px !important;
   }
   .single_de p,
   .single_de p a {
      font-size: 15px;
      font-weight: 300;
   }
   .cir img {
      height: 102px;
   }
   .shapping_22 img {
      width: 159px;
      height: auto;
   }
   .as_img img {
      width: 50%;
   }
   .as_img {
      position: absolute;
      top: -4%;
      left: 10%;
      z-index: 1;
   }
   .shapping_22 {
      position: absolute;
      top: -21%;
      left: 10%;
      z-index: 1;
   }
   .booking_area_design {
      padding: 145px 0;
      margin-top: -82px;
      position: relative;
      padding-bottom: 121px;
      z-index: 1;
   }
   .flex-row .row {
      flex-direction: column-reverse;
   }
   .default_top {
      justify-content: center;
      align-items: center;
   }
   .default_content_full {
      margin-bottom: 33px;
   }
   .de_content p {
      color: #fff;
      text-align: center !important;
      overflow-wrap: anywhere;
   }
   .rooms_default_area {
      background-size: cover !important;
      background-position: center center !important;
   }
   .margin_class {
      margin-top: -89px;
      padding-top: 151px;
   }
   .shap_position {
      position: absolute;
      left: -12%;
      right: 0;
      width: 88%;
      height: 63px;
      transform: rotate(2deg);
      bottom: 13px;
   }
   .shap_position img {
      width: 127.2%;
   }
   .rooms_default_area3 {
      margin-top: -89px;
   }
   .create_shap {
      top: 37px;
      transform: rotate(-3deg);
      z-index: 999999;
   }
   .tab_menu {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 37px;
   }
   .all_content {
      flex: 0 0 100%;
      max-width: 100%;
   }
   .games_content {
      margin-left: 0;
   }
   .button-search-score {
      padding-left: 3.25rem !important;
      padding-right: 3.25rem !important;
   }
}
@media (max-width: 311px) {
   .default_top {
      flex-direction: column;
   }
}
@media (max-width: 340px) {
   .de_left img {
      width: 35px;
   }
}

@media all and (max-width: 767px) {
   .registerComplete_title img {
      width: 65%;
   }
   .first-change {
      display: block;
   }
   .second-change {
      display: block;
      padding-top: 3.5%;
   }
   .account-br {
      display: none;
   }
   .account-page-btn {
      font-size: 15px !important;
   }
   .second-change {
      padding-left: 5.4vw !important;
      padding-right: 5.4vw !important;
   }
   .banner_all_content h1 {
      font-size: 34px;
   }
   .book_now a {
      color: #fff;
      font-size: 21px;
      position: relative;
   }
   .heading h2 {
      font-size: 25px;
   }
   .video_area {
      min-height: 300px !important;
   }
   .about_area_design {
      background-size: cover !important;
   }
   .about_area_design {
      padding-bottom: 137px;
   }
   .s_img img {
      height: 47px;
   }
   .single_game {
      flex: 0 0 100%;
      max-width: 100%;
      position: relative;
   }
   .shapping_22 img {
      width: 153px;
      height: auto;
   }
   .shapping_22 {
      position: absolute;
      top: -7%;
      left: 1%;
      z-index: 1;
   }
   .shap-img {
      display: none;
   }
   .as_img {
      position: absolute;
      top: -4%;
      left: 0;
      z-index: 1;
      text-align: center;
   }
   .footer_area_design {
      padding: 51px 0;
      background: #000;
      padding-bottom: 53px;
   }
   .c_title h2 {
      color: #fff;
      font-weight: 700;
      margin-bottom: 9px;
      font-size: 25px;
   }
   .all_map_area {
      margin-top: 10px;
   }
   .copyright p,
   .copyright p a {
      color: rgb(15, 216, 57, 0.7);
      font-weight: 300;
      text-align: center;
   }
   .copyright {
      margin-top: 32px;
   }
   .all_icons a {
      color: #fff;
      font-size: 23px;
   }
   .single_g_search.single_g_search1 {
      flex: 0 0 100%;
   }
   .single_g_search.single_g_search2 {
      flex: 0 0 100%;
      margin-top: 16px;
   }
   .single_g_search.single_g_search2 input[type="submit"] {
      margin: auto;
      width: 100%;
      cursor: pointer;
   }
   .single_g_search.single_g_search1 input {
      /* margin: auto; */
      width: 105%;
   }
   .input_position {
      top: 32px;
      left: 34px;
   }
   .find_tab ul li {
      flex: 0 0 100%;
      max-width: 100%;
   }
   .single_g_search input {
      height: 87px;
      font-size: 15px;
   }
   .tab_menu ul li a {
      font-size: 17px;
      font-weight: 400;
      padding: 0 20px;
      min-height: 61px;
   }
   .rank.rank_1 {
      max-width: 200px;
      flex: 0 0 15%;
      text-align: center;
      width: 200px !important;
   }
   .rank.rank_2 {
      flex: auto;
      max-width: 300px;
      width: 300px !important;
   }
   .rank.rank_3 {
      flex: auto;
      max-width: 144px;
      width: 100%;
   }
   .ranking_items {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
   }
   .a_single_a {
      background-size: cover !important;
   }
   .fr_title h1 {
      font-size: 46px;
      font-weight: 700;
      margin-bottom: 14px;
      text-align: center;
   }
   .register_complete_title h1 {
      font-size: 50px;
   }
   .fr_title p {
      font-size: 16px;
      margin-top: 25px;
   }
   .subtitle_text {
      font-size: 4.5vw !important;
   }
   .single_fra {
      margin-bottom: 48px;
   }
   .single_fra p {
      font-size: 15px;
      text-align: center;
   }
   .single_fra h3 {
      text-align: center;
   }
   .contact_us_more h3 {
      font-size: 30px;
      max-width: 500px;
      text-align: center;
      margin: 0 auto;
      margin-top: 28px;
   }
   .p_line.p_line1 {
      right: 0;
      bottom: 14px;
   }
   .p_line.p_line2 {
      right: 10%;
      bottom: 23px;
   }
   .login_single.login_btn.register_btn input {
      width: 290px;
   }
   .fr_title {
      margin-bottom: 40px;
      padding-bottom: 1px;
   }
   .fr_title h1 {
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 14px;
      text-align: center;
   }
   .price_title img {
      width: 68%;
   }
   .btn-gifts {
      justify-content: center;
   }
}

@media all and (max-width: 575px) {
   .banner_area_desing {
      padding-top: 97px;
      padding-bottom: 92px;
   }
   .heading h2 {
      padding: 24px 0;
      text-align: center;
   }
   .single_item {
      text-align: center;
      flex: 0 0 100%;
      margin-bottom: 30px;
   }
   .single_item:last-child {
      margin-bottom: 0;
   }
   .all_item_area {
      flex-wrap: wrap;
   }
   .video_area {
      min-height: 250px;
   }
   .banner_all_content h1 {
      font-size: 28px;
   }
   .book_now a {
      color: #fff;
      font-size: 17px;
      position: relative;
   }
   .book_now a::after,
   .book_now a::before {
      width: 3px;
   }
   .book_now::after,
   .book_now::before {
      height: 3px;
   }
   .de_left img {
      width: 76px;
      margin-right: 16px;
   }
   .d_text_img img {
      height: 48px;
   }
   .img img {
      height: 88px;
   }
   .de_position {
      bottom: -32px;
   }
   .default_top2 .d_text_img img {
      height: 80px;
      margin-bottom: 23px;
   }
   .default_top2 .de_position {
      bottom: -32px;
   }
   .default_top2 .de_left img {
      width: 91px;
      margin-right: 0;
      margin-left: 7px;
   }
   .text3 img {
      height: 55px !important;
      margin-bottom: 0 !important;
   }
   .section_padding2 {
      padding: 95px 0;
   }
   .shap_position {
      bottom: 9px;
      transform: rotate(0deg);
   }
   .top_rank_con {
      display: block;
   }
   .top_rank_right {
      display: flex;
      margin-top: 24px;
   }
   .p_color a {
      background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
      cursor: pointer;
      font-family: "sui-generis";
      padding: 14px;
      color: #fff;
      border: none;
      outline: none;
      margin-left: -15px;
      height: 81px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-top: 15px;
      width: 100%;
      font-size: 12px;
   }
   .ar_text {
      font-size: 16px;
      font-weight: 700;
      text-align: right;
      line-height: normal;
   }
   .ac_con {
      max-width: 88px;
      height: 70px;
      font-size: 20px;
      font-weight: 700;
   }
   .p_color p {
      font-size: 15.5px;
      font-weight: 700;
   }
   .a_single_a h3 {
      color: #fff;
      font-size: 16px;
      margin-bottom: 31px;
   }
   .earn-more-energize {
      font-size: 12px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
   }
   .button-achivement {
      text-align: center;
      width: 120%;
      margin-left: -8%;
   }
   .ar_icon img {
      width: 74px;
      margin-top: 0;
   }
   .p_color {
      text-align: center;
   }
   .ar_text h2 {
      font-size: 28px;
   }
   .p_color_next {
      margin-top: -8px;
   }
}

@media (max-width: 673px) {
   .shap2 {
      top: -2.2% !important;
   }
   .shap1 {
      top: 2.5%;
   }
}

@media all and (max-width: 451px) {
   .play-video img {
      width: 20vw;
   }
   .button-search-score {
      font-size: 15px !important;
   }
   .ranking_all h2 {
      font-size: 19px;
   }
   .table-rankings thead tr th {
      font-size: 14.5px;
   }
   .table-rankings tbody tr {
      font-size: 12px;
   }
   .logo img {
      height: 34px;
      width: auto;
   }
   .banner_area_desing {
      padding-top: 68px;
      padding-bottom: 69px;
      /* margin-top: 45px; */
   }
   .shap {
      position: absolute;
      width: 34%;
   }
   .shap1 {
      right: 0;
      /*top: -0.5%;*/
   }
   .shap2 {
      top: -2.3% !important;
   }
   .heading p {
      font-size: 14px;
      margin-top: 14px;
   }
   .video_area {
      min-height: 201px !important;
   }
   .booking_area_design {
      padding: 101px 0;
      margin-top: -82px;
      position: relative;
      padding-bottom: 89px;
      z-index: 1;
      background-size: cover !important;
   }
   .cir img {
      height: 62px;
      margin-top: 19px;
   }
   .copyright p,
   .copyright p a {
      font-size: 14px;
   }
   .map_con p {
      font-size: 14px;
   }
   .single_de p,
   .single_de p a {
      font-size: 14px;
      font-weight: 300;
   }
   .nav.nav-tabs.games_in_tab {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
   }
   .nav.nav-tabs.games_in_tab li {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
   }
   .games_tab_pannel_content th,
   .games_tab_pannel_content td {
      color: #fff;
      border: none;
      font-size: 14px;
   }
   .nav.nav-tabs.games_in_tab li a {
      color: #fff;
      border-left: navajowhite;
      border-bottom: 1px solid #fff !important;
      display: block;
      padding: 13px 0;
      font-size: 13px;
   }
   .games_tab_pannel_content {
      padding: 8px;
   }
   .find_tab ul li a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      padding: 0 21px;
      text-transform: uppercase;
   }
   .tab_menu ul li a {
      font-size: 15px;
      font-weight: 400;
      padding: 0 20px;
      min-height: 55px;
   }
   .card-header {
      height: 48px;
      font-size: 14px;
   }
   .nav.nav-tabs.games_tab a {
      font-size: 12px;
   }
   .p_color a {
      background: url(../images/games/submit.png) no-repeat scroll 0 0 / 100% 100%;
      cursor: pointer;
      font-family: "sui-generis";
      padding: 14px;
      color: #fff;
      border: none;
      outline: none;
      margin-left: 0;
      font-size: 15px;
      font-weight: 400;
      max-width: 128%;
      height: 81px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-top: 15px;
      width: 124%;
      font-size: 11px;
      margin-left: -8%;
   }
}

@media (max-width: 530px) {
   .modal-body {
      padding: 0vw 2vw 0vw;
   }
   .account-page-btn {
      font-size: 3.5vw !important;
   }
   .second-change {
      padding-left: 7vw !important;
      padding-right: 7vw !important;
   }
}

@media (max-width: 768px) {
   .events-icon-row1 {
      margin-top: 0%;
   }
   .getEnergized_title {
      max-width: 520px;
   }
   .space-prices {
      margin-top: 61%;
   }
   .container_coming_soon h1 {
      font-size: 50px;
   }
   .prices_icons img {
      padding-bottom: 7.7px;
   }
   .price_hours {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
   }
   .price_hours-right {
      border-top-right-radius: 0px;
   }
   .price_hours-left {
      border-top-left-radius: 0px;
   }
   .price_hours_container {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
   }
   .price_hours_container-left {
      border-bottom-left-radius: 20px;
   }
   .price_hours_container-right {
      border-bottom-right-radius: 20px;
   }
   .all_social {
      margin-top: 38px;
      margin-bottom: 18px;
   }
   .w-img2 {
      width: 27.5%;
   }
   .bg-3 {
      background-size: cover !important;
      background-position: center center !important;
   }
   .bg-prices {
      background: url("../../assets/images/prices/Prices_Mobile.webp") no-repeat scroll 0 0 / 100% 100%;
   }
   .bg-events {
      background: url("../../assets/images/events/Corporate-Parties_Hero_img_Mobile_updated2.jpg") no-repeat scroll 0 0 / 100% 100%;
   }
   /* .bg-giftVouchers {
      background: url("../../assets/images/giftVouchers/Gift_Vouchers_Mobile.webp") no-repeat scroll 0 0 / 100% 100%;
   } */
   .bg-checkScores {
      background: url("../../assets/images/checkScores/Scores_Mobile.webp") no-repeat scroll 0 0 / 100% 100%;
   }
}
@media (max-width: 520px) {
   .space-prices {
      margin-top: 300px;
   }
}

@media (max-width: 450px) {
   .prices_icons2 img {
      padding-bottom: 6.5px;
   }
}

@media (max-width: 991px) {
   .video_area {
      min-height: 400px;
   }
   .banner_area_desing {
      background-size: cover !important;
      background-position: center center !important;
   }
   .about_area_design {
      background-size: cover !important;
      background-position: center center !important;
   }
   .checkbox-left {
      margin-left: -17px;
   }
   .button_book_now a {
      font-size: 24px;
      padding: 7px 20px;
      text-align: center;
   }
   .btn_events a {
      font-size: 19.5px;
   }
   .franchise_title img {
      width: 80% !important;
   }
   .login_single.single_fra_input input {
      padding-left: 21px;
   }
   .login_single.single_fra_input input::placeholder {
      transform: translate3d(0, -5%, 0);
   }
}

@media (max-width: 767px) {
   .all_item_area {
      flex-wrap: wrap;
   }
   .single_item {
      text-align: center;
      flex: 0 0 100%;
      margin-bottom: 30px;
   }
   .footer_sm {
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: center;
   }
   .single_de {
      justify-content: center;
   }
   .all_icons {
      margin-left: 18px;
   }
   .franchise_title img {
      width: 100% !important;
   }
   .modal-location-title h2 {
      padding-top: 30px;
   }
   .modal-location-title h3 {
      padding-bottom: 4px;
   }
   .modal-location-image {
      height: 190px;
   }

   #details {
      order: 1;
   }
   #lines {
      order: 2;
   }
   #map {
      order: 3;
   }
   #booknow {
      order: 4;
   }
   #allsocial {
      order: 5;
   }
   #copyright {
      order: 6;
   }
}

@media (max-width: 585px) {
   .modal-location-image {
      height: 150px;
   }
   .getEnergized_title {
      max-width: 540px;
   }
}
@media (max-width: 469px) {
   .modal-location-image {
      height: 120px;
   }
   .button_book_now a {
      font-size: 19px;
   }
   .recover_password_title p {
      font-size: 13px;
   }
   .recoverPassword_mobile {
      width: 100% !important;
      margin-left: 0;
   }
}
@media (max-width: 388px) {
   .modal-location-image {
      height: 90px;
   }
}

@media (max-width: 991px) {
   .checkbox-style {
      width: 2.8em;
      height: 2.8em;
   }
   .link {
      font-size: 16px;
   }
   .login_single a {
      font-size: 16px;
   }
   .very_long_input input {
      padding-left: 31px !important;
   }
   .login_single.single_fra_input input {
      height: 60px;
   }
   .login_single.single_fra_input input::placeholder {
      font-size: 18px;
      padding-left: 10px;
   }
   .single_item_events p {
      font-size: 11px;
   }
   .s_img_events img {
      height: 99px;
   }
   .ranking_all {
      margin-left: 0;
      max-width: 97%;
   }
   .account_summary_2 {
      margin-left: 0;
   }
   .games_content_red {
      margin-left: 0;
   }
   .games_content_green {
      margin-left: 0;
   }
}

@media (max-width: 767px) {
   .a_single_a h3 {
      font-size: 16px;
      margin-left: 0;
   }
   .w-img {
      width: 108%;
      margin-left: -4%;
   }
   .login-register-title img {
      width: 100% !important;
   }
   .logIn_subtitle img {
      width: 26.3% !important;
      margin-left: 0.2%;
   }
   .checkbox-left {
      margin-left: -9.5px;
   }
   .recoverpasswordbtn img {
      width: 100% !important;
   }
   .password-reset img {
      width: 65%;
   }
   .titleChange {
      width: 80%;
   }
   .titleChange-password {
      width: 100%;
   }
   .very_long_input input {
      background: url(../images/games/input.png) no-repeat scroll 0 / 100% 117% !important;
      width: 100% !important;
      margin-left: 0 !important;
      padding-left: 21px !important;
   }
   .shapping_22.fra2 {
      top: 0 !important;
   }
   .contact_us_more h3 {
      margin-top: 116px;
   }
   .or_text {
      font-weight: 300 !important;
      font-size: 19px !important;
      margin-top: 17px;
      margin-bottom: -6px;
   }
   .notFound p {
      font-size: 19px !important;
   }
   .iframe_home {
      height: 247px;
   }
   .iframe_home2 {
      height: 247px;
   }
   .shap2 {
      top: -3.6%;
   }
   .faq_title h1 {
      font-size: 40px;
   }
   .gift-voucher-btn {
      font-size: 19px !important;
   }
   .event_icon_area h2 {
      text-align: center;
   }
   .event_icon_area p {
      text-align: center;
   }
   .single_item_events p {
      font-size: 13px !important;
   }
   .gift_title {
      text-align: center;
      margin: auto;
   }
   .gift_title img {
      width: 100%;
   }
   .gift_title h1 {
      text-align: center;
   }
   .gift_title h2 {
      text-align: center;
   }
   .gift_title p {
      text-align: center;
   }
   .modal-location-icons {
      padding-top: 25px;
   }
   .modal-location-all {
      padding-top: 0;
   }
}

@media (max-width: 596px) {
   .shapping_22.fra2 {
      left: 10%;
   }
}

@media (max-width: 499px) {
   .check_scores_title h1 {
      text-align: center;
      font-size: 40px;
   }
   .check_scores_title p {
      text-align: center;
      font-size: 16px;
   }
   .notFoundTitle {
      width: 46.5% !important;
   }
}
@media (max-width: 451px) {
   .shap2 {
      top: -2.3%;
   }
   .heading-aboutUs {
      /*margin-top: -16px !important;*/
   }
}

@media (max-width: 350px) {
   .faq_title h1 {
      font-size: 32px;
   }
}

@media (max-width: 378px) {
   .check_scores_title h1 {
      font-size: 32px;
   }
   .check_scores_title p {
      font-size: 13px;
   }
}

@media (min-width: 900px) {
   .subtitle_text {
      font-size: 2.2vw !important;
   }
   .second-change-BTN {
      margin-left: -3%;
   }
}
@media (min-width: 991px) {
   .prices_icons2 img {
      padding-top: 11px;
   }
   .price_title img {
      width: 30%;
   }
}

@media (min-width: 768px) {
   .single-card-price {
      max-width: 470px;
      margin: auto;
      text-align: center;
   }
   .single-card-price-players img {
      width: 27%;
   }
   .single-icon-price img {
      width: 31.5%;
   }
   .gift_title img {
      margin-left: -2%;
   }
   .map_con {
      margin-left: 4%;
   }
}

@media (min-width: 1200px) {
   .shap1 {
      top: -1.2%;
   }
   .shap2 {
      top: -5%;
   }
}
@media (min-width: 1450px) {
   .shap1 {
      top: -2.5%;
   }
   .shap2 {
      top: -6%;
   }
   .heading-aboutUs h2 {
      padding-top: 16px;
   }
   .account-page-btn {
      font-size: 0.9vw !important;
   }
   .second-change {
      padding-left: 2.65vw !important;
      padding-right: 2.65vw !important;
   }
}
@media (min-width: 1700px) {
   .shap1 {
      top: -3.5%;
   }
   .shap2 {
      top: -7.5%;
   }
}
@media (min-width: 1810px) {
   .single_item p {
      padding-left: 1vw;
      padding-right: 1vw;
   }
}
@media (min-width: 1950px) {
   .shap1 {
      top: -3.5%;
   }
   .shap2 {
      top: -8.5%;
   }
   .account-page-btn {
      font-size: 0.7vw !important;
   }
}
@media (min-width: 2310px) {
   .shap1 {
      top: -7%;
   }
   .shap2 {
      top: -9%;
   }
}
@media (min-width: 2490px) {
   .shap1 {
      top: -9.5%;
   }
   .shap2 {
      top: -9.5%;
   }
}
@media (min-width: 2730px) {
   .shap1 {
      top: -5.8%;
   }
   .shap2 {
      top: -9.8%;
   }
}

@media (max-width: 461px) {
   .iframe_home {
      height: 163px;
   }
   .iframe_home2 {
      height: 163px;
   }
   .home-video-container {
      height: 92vh;
   }
}
@media (min-width: 991px) {
   .iframe_home {
      height: 415px;
      width: 87%;
   }
   .iframe_home2 {
      height: 416px;
      width: 829px;
      margin-left: 6px;
      object-fit: cover;
   }
}
@media (max-width: 1362px) {
   .p_line_event_green3 {
      right: 42%;
   }
}
@media (max-width: 820px) {
   .p_line_event_green3 {
      right: 37%;
   }
}
@media (max-width: 450px) {
   .p_line_event_green3 {
      right: 24%;
   }
   .password-reset img {
      width: 92%;
   }
   .titleChange {
      width: 100%;
   }
   .second-change {
      padding-left: 14.5vw !important;
      padding-right: 14.5vw !important;
   }
   .first-change {
      padding-left: 10vw !important;
      padding-right: 10vw !important;
   }
}
@media (max-width: 991px) {
   #menu img {
      margin-left: 252px;
      top: 103px;
   }
}
@media (max-width: 438px) {
   #menu img {
      margin-left: 243px;
   }
   .location_pin_icon {
      margin-top: -2.88px;
   }
   .single_g_search.single_g_search1 {
      margin-left: 2.85px;
   }
   .single_g_search.single_g_search1 input {
      background: url(../images/games/input.png) no-repeat scroll 350% 100%/99.5% 100%;
   }
}
@media (max-width: 468px) {
   .modal-location-icons img {
      width: 57px;
   }
   .create_shap {
      top: 25px;
   }
   .gift-voucher-btn {
      font-size: 15px !important;
   }
   .w-img2 {
      width: 35%;
   }
   .book-now-home {
      font-size: 18.5px !important;
   }
}

@media (min-width: 1200px) {
   .prices-container {
      max-width: 903px;
   }

   .checkscores-container {
      max-width: 903px;
   }

   .auth-container {
      max-width: 903px;
   }

   .recoverpassword-container {
      max-width: 903px;
   }
   .recoverpasswordsuccess-container {
      max-width: 870px;
   }

   .account-container {
      max-width: 903px;
   }

   .changeemail-container {
      max-width: 903px;
   }
   .changeemailsuccess-container {
      max-width: 870px;
   }

   .changepassword-container {
      max-width: 903px;
   }
   .changepasswordsuccess-container {
      max-width: 870px;
   }
}
